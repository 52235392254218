import React from 'react';
import ReactDOM from "react-dom/client";
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {BrowserRouter, Route, Routes} from 'react-router-dom';
import 'jquery/dist/jquery.min.js';
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
import 'font-awesome/css/font-awesome.min.css';
import Home from './component/home';
import Footer from './component/common/footer';
import {store} from './store/store';
import { persistStore } from 'redux-persist';
import { createRoot } from 'react-dom/client';
import ReactGA from "react-ga4";
import NewTrails from "./component/trail";
import InterestCalculater from "./component/interest";
import MortgageCalculator from "./component/mortgage";
const container = document.getElementById('root');
// @ts-ignore
const root = createRoot(container);
let persistor = persistStore(store);

ReactGA.initialize("G-SN1K1T77DC");
ReactGA.send(window.location.pathname);

root.render(


        <BrowserRouter>
            {/*<Nav />*/}
            <Routes>

                <Route path='/' element={<Home />} />
                <Route path='/add/trail' element={<NewTrails />} />

                <Route path='/interest-calculator' element={<InterestCalculater />} />
              <Route path='/mortgage-calculator' element={<MortgageCalculator />} />
                 </Routes>

        </BrowserRouter>);

