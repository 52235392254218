import React from 'react';
import Latest from './home/latest';
import Popular from "./home/popular";
import {NSFAS_DETAILS, SPACES} from "./constants/constants";

import  '../index.css'
function Home() {

    return (
        <>

            <section className="dark:bg-gray-800 dark:text-gray-100">
                <div className="container mx-auto flex flex-col items-center px-4 py-16 text-center md:py-32 md:px-10 lg:px-32 xl:max-w-3xl">
                    <h1 className="text-4xl font-bold leadi sm:text-5xl">You should not be here. go back to where you came from!
                    </h1>

                </div>
            </section>

        </>

    );
}

export default Home;
